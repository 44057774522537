import { updatePhotographerJobStatus } from '@ht-lib/accounts-common'
import { Job, PhotographerJobStatus } from '@ht-lib/accounts-models'
import {
  addUploadSession,
  ImageUploadSessionSnapshot,
  updateUploadSession,
  UploadEvent,
  UploadImageEvent,
  UploadManager
} from '@ht-lib/image-util'
import { getUnixTime } from 'date-fns'
import firebase from 'firebase/compat/app'
import Notify from 'quasar/src/plugins/Notify.js';
import Vue from 'vue'

export interface UploadJob {
  job: Job
  studioJobId: string
  uploadSessionRef?: ImageUploadSessionSnapshot
}

function unixNow (): number {
  return getUnixTime(new Date())
}

const onImageUploaded: UploadImageEvent<UploadJob> = async (uploadJob, img) => {
  const currentJob = uploadJob.meta
  const now = unixNow()
  if (currentJob.uploadSessionRef !== undefined) {
    await updateUploadSession(currentJob.uploadSessionRef.id, {
      imagesUploaded: firebase.firestore.FieldValue.increment(1) as unknown as number,
      uploadedMB: firebase.firestore.FieldValue.increment(img.file.size / 1024 / 1024) as unknown as number,
      duration: now - uploadJob.startTime
    })
  }
}

const onJobFinish: UploadEvent<UploadJob> = async (uploadJob) => {
  const currentJob = uploadJob.meta
  if (currentJob === undefined) return

  Notify.create({
    message: `${ uploadJob.description } has finished uploading`,
    position: 'top'
  })
  await updatePhotographerJobStatus(uploadJob.id, uploadJob.uploaderId, PhotographerJobStatus.COMPLETE)
  if (currentJob.uploadSessionRef !== undefined) {
    const endTime = unixNow()
    await updateUploadSession(currentJob.uploadSessionRef.id, {
      endTime,
      duration: endTime - currentJob.uploadSessionRef.data().startTime
    })
  }
}

const onNextJob: UploadEvent<UploadJob> = async (uploadJob) => {
  const { job, studioJobId } = uploadJob.meta
  const ref = await addUploadSession({
    accountId: job.accountId,
    jobId: uploadJob.id,
    userId: uploadJob.uploaderId,
    bookingId: job.bookingId,
    studioJobId,
    imageCount: uploadJob.images.length,
    imagesUploaded: 0,
    uploadedMB: 0,
    endTime: null,
    duration: null
  })

  uploadJob.meta.uploadSessionRef = await ref.get()
}

const onImageError: UploadImageEvent<UploadJob> = async (uploadJob, img) => {
  Notify.create({
    message: `Failed to upload an image: ${ img.longRef } for job: ${ uploadJob.description }. Please start again, if this issue persists please get in contact with dev support.`,
    timeout: 0,
    actions: [{
      label: 'Start again',
      color: 'white',
      handler: () => {
        window.location.reload()
      }
    }],
    type: 'negative'
  })

  return await Promise.resolve()
}

let uploader: UploadManager<UploadJob>

export function getUploader (): UploadManager<UploadJob> {
  if (uploader === undefined) {
    uploader = Vue.observable(
      new UploadManager<UploadJob>({
        onJobFinish,
        onImageUploaded,
        onNextJob,
        throwError: true,
        onImageError
      })
    )
  }

  return uploader
}
