import Default from '@/layouts/Default/Default.vue'
import { JobSnap, updateAccount, updateJob } from '@ht-lib/accounts-common'
import { Job, JobStatus } from '@ht-lib/accounts-models'
import { ControlConfig, route as QCRoute } from '@ht-vue/quality-control'
import { getUnixTime } from 'date-fns'
import { RouteConfig } from 'vue-router'

const headerHeight = 50
const uploadStepperStepsHeight = 76
const uploadStepperNavHeight = 40
const uploadStepperPadding = 8
export const heightAccountHolderReview = headerHeight
export const heightUploadStepper = headerHeight + uploadStepperStepsHeight + uploadStepperNavHeight + uploadStepperPadding

function getNextJobStatus (job: Job): JobStatus {
  switch (true) {
    case job.market === 'S' && !job.hasCommissionValues:
      return JobStatus.AWAITING_COMMISSION
    case job.market === 'S' && !job.hasAimsJobNumber:
      return JobStatus.REQUIRES_AIMS_NO
    default:
      return JobStatus.READY_FOR_QC
  }
}

const qcRouteSettings: ControlConfig = {
  finish: {
    route: '/accounts',
    cb: async (jobSnapshot?: JobSnap): Promise<void> => {
      const job = jobSnapshot?.data()
      console.log('QC FINISH, job:', job)

      if (job != null) {
        const status = getNextJobStatus(job)
        const committedUnixTimestamp = getUnixTime(new Date())

        await Promise.all([
          updateJob(jobSnapshot.id, {
            status,
            isCommittedToLab: true,
            committedToLabUnixTimestamp: committedUnixTimestamp
          }),
          updateAccount(job.accountId, {
            lastJobUploadedUnixTimestamp: committedUnixTimestamp
          })
        ])
      }
    }
  },
  save: {
    route: '/accounts',
    cb: (job?: JobSnap) => {
      const data = job?.data()
      console.log('QC SAVE job:', data)
    }
  },
  inLab: false,
  showFinishSave: true,
  showPrintWorksheet: false,
  height: heightAccountHolderReview
}

const uploadQcConfig: ControlConfig = {
  save: {
    route: '/',
    cb: (job?: JobSnap) => {
      const data = job?.data()
      console.log('Upload QC SAVE job:', data)
    }
  },
  finish: {},
  showFinishSave: false,
  showSave: true,
  inLab: false,
  showPrintWorksheet: false,
  routeNamePrefix: 'upload',
  height: heightAccountHolderReview
}

export const routes: RouteConfig[] = [
  {
    path: '/',
    component: Default,
    children: [
      {
        path: '',
        component: async () => await import(/* webpackPrefetch: true */ '@/pages/Bookings.vue'),
        name: 'default'
      },
      {
        path: 'resize-test',
        component: async () => await import(/* webpackPrefetch: true */ '@/pages/ResizeImageTest.vue'),
        name: 'resize'
      },
      {
        path: 'booking/:bookingId/upload/:jobId?',
        props: true,
        component: async () => await import(/* webpackPrefetch: true */ '@/pages/JobForm.vue')
      },
      {
        path: 'labUpload/:bookingId/upload/:jobId?',
        name: 'uploadToBooking',
        props: route => ({ ...route.params, isLabUploader: true }),
        component: async () => await import(/* webpackPrefetch: true */ '@/pages/JobForm.vue')
      },
      {
        path: 'accounts',
        component: async () => await import(/* webpackPrefetch: true */ '@/pages/Accounts.vue')
      },
      {
        path: 'worksheet/:jobId',
        name: 'worksheet',
        props: route => ({ ...route.params }),
        component: async () => await import(/* webpackPrefetch: true */ '@/pages/Worksheet.vue')
      },
      {
        path: 'labUpload',
        component: async () => await import(/* webpackPrefetch: true */ '@/pages/LabUpload.vue')
      },
      QCRoute(uploadQcConfig),
      QCRoute(qcRouteSettings)
    ]
  },
  {
    path: '/401',
    name: '401',
    component: async () => await import(/* webpackPrefetch: true */ '@/pages/Error401.vue')
  },
  { // Always leave this as last one
    path: '*',
    component: async () => await import(/* webpackPrefetch: true */ '@/pages/Error404.vue')
  }
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about", webpackPrefetch: true */ '../views/About.vue')
  // }
]

export default routes
