import App from '@/App.vue'
import '@/quasar'
import VueGoogleSignIn from '@ht-lib/vue-google-signin'
import Vue from 'vue'
import Vuelidate from 'vuelidate'
import { auth } from './firebase'
import { onAuthStateChanged } from './logRocket'
import router from './router'
import axios from 'axios'
import * as qualityControl from '@ht-vue/quality-control'
import './axios'

qualityControl.setAxios(axios)

Vue.use(Vuelidate)
Vue.use(new VueGoogleSignIn(), {
  router,
  appName: 'Upload',
  firebaseAuth: auth,
  defaultRouteName: 'default',
  onAuthStateChanged
})

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
