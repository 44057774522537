import { User } from '@ht-lib/vue-google-signin'
import LogRocket from 'logrocket'

export let onAuthStateChanged: (user: User | null) => void
if (process.env.NODE_ENV === 'production') {
  onAuthStateChanged = (user) => {
    if (user != null) {
      LogRocket.identify(user.uid, {
        name: user.name,
        email: user.email,
        app: 'upload'
      })
    }
    console.log('LogRocket:SetUser:Completed')
  }

  LogRocket.init('kxfnbf/uploadtempestappsio', {
    console: {
      shouldAggregateConsoleErrors: true
    },
    release: process.env.version
  })
}
