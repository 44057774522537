import axios from 'axios'
import { Auth } from 'firebase/auth'

axios.defaults.baseURL = process.env.VUE_APP_FIREBASE_BASE_URL

export function setupAxiosAuthInterceptor (auth: Auth): void {
  axios.interceptors.request.use(async (requestConfig) => {
    try {
      if (requestConfig.url.startsWith('/')) {
        const idToken = await auth.currentUser.getIdToken()
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        requestConfig.headers.Authorization = 'Bearer ' + idToken
      }
      return requestConfig
    } catch (error) {
      console.error(error)
      return await Promise.reject(error)
    }
  }, async (error) => {
    console.error(error)
    return await Promise.reject(error)
  })
}
